import { firstValueFrom, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import {
  Grant,
  GrantNote,
  GrantShare,
  Pagination,
  Proposal,
  SearchGrantFilter,
} from '@app/models';
import { GrantResponse, PaginationResponse } from '@app/models/dto/response';
import {
  GrantNoteRequest,
  GrantShareRequest,
  UpdateProposalStatusRequest,
  UpdateProposalTagRequest,
} from '@app/models/dto/request';
import { GrantFilter } from '@app/components/grants/grant-filter/types';
import {
  ProposalStatus,
  ProposalStatusEvent,
  ProposalTag,
} from '@app/models/enums';
import { TrackerService } from '@app/services/tracker.service';
import { KanbanFilter } from '@app/pages/my-grants/components/kanban-filter/types';
import { Measurement } from '@app/models/enums/Measurement';

@Injectable({
  providedIn: 'root',
})
export class GrantRepository {
  constructor(
    private http: HttpClient,
    private trackerService: TrackerService
  ) {}

  async searchGrants({
    orderBy = 'published_at',
    orderDirection = 'desc',
    page = 0,
    perPage = 10,
    filter,
  }: {
    orderBy?: string;
    orderDirection?: string;
    page?: number;
    perPage?: number;
    filter: SearchGrantFilter;
  }): Promise<Pagination> {
    const url = `${environment.api.baseURL}/grants`;

    const params = new HttpParams()
      .set('order_by', orderBy)
      .set('order_direction', orderDirection)
      .set('page', page)
      .set('per_page', perPage);

    const body = {
      text: filter.searchText ?? '',
      tags: filter.searchTags ?? [],
      base_budget_from: filter.baseBudgetFrom,
      base_budget_to: filter.baseBudgetTo,
      modified_at_from: filter.modifiedAtFrom
        ? filter.modifiedAtFrom.toJSON()
        : null,
      modified_at_to: filter.modifiedAtTo ? filter.modifiedAtTo.toJSON() : null,
      cpv_groups: filter.cpvGroupIds || [],
      regions: filter.regionIds || [],
      deadline_max_days: filter.deadlineMaxDays ?? 'null',
      some_lots: filter.someLots,
      one_lot: filter.oneLot,
      maybe_extension: filter.maybeExtension,
      without_extension: filter.withoutExtension,
    };

    const response = await firstValueFrom(
      this.http
        .post<PaginationResponse>(url, body, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(grant =>
              Grant.fromResponse(grant)
            );
            return response;
          })
        )
        .pipe(
          map(response => {
            response.data = (response.data || []).map(grant => {
              grant.readed = true;
              return grant;
            });
            return response;
          })
        )
    );

    return response;
  }

  async getGrantById(grantId: number): Promise<Grant> {
    const url = `${environment.api.baseURL}/grants/${grantId}`;

    const response = await firstValueFrom(
      this.http
        .get<GrantResponse>(url)
        .pipe(map(response => Grant.fromResponse(response)))
    );

    return response;
  }

  async getRecommendedGrants({
    orderBy = 'published_at',
    orderDirection = 'desc',
    page = 0,
    perPage = 10,
    filter,
  }: {
    orderBy?: string;
    orderDirection?: string;
    page?: number;
    perPage?: number;
    filter: GrantFilter;
  }): Promise<Pagination> {
    const url = `${environment.api.baseURL}/my-grants/recommended`;

    const params = new HttpParams()
      .set('order_by', orderBy)
      .set('order_direction', orderDirection)
      .set('page', page)
      .set('per_page', perPage);

    const body = {
      text: filter.searchText ?? '',
      tags: filter.searchTags ?? [],
      base_budget_from: filter.baseBudgetFrom,
      base_budget_to: filter.baseBudgetTo,
      modified_at_from: filter.modifiedAtFrom
        ? filter.modifiedAtFrom.toJSON()
        : null,
      modified_at_to: filter.modifiedAtTo ? filter.modifiedAtTo.toJSON() : null,
      cpvs: (filter.cpvs || []).map(cpv => cpv.id),
      regions: (filter.regions || []).map(region => region.id),
      deadline_max_days: filter.deadlineMaxDays ?? 'null',
      not_readed: filter.notReaded,
      readed: filter.readed,
      is_discarted: filter.isDiscarded,
      is_favorite: filter.isFavorite,
      not_qualified: filter.notQualified,
      some_lots: filter.someLots,
      one_lot: filter.oneLot,
      maybe_extension: filter.maybeExtension,
      without_extension: filter.withoutExtension,
    };

    const response = await firstValueFrom(
      this.http
        .post<PaginationResponse>(url, body, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(grant =>
              Grant.fromResponse(grant)
            );
            return response;
          })
        )
    );

    return response;
  }

  async getMyGrants({
    orderBy = 'published_at',
    orderDirection = 'desc',
    page = 0,
    perPage = 10,
    filter,
  }: {
    orderBy?: string;
    orderDirection?: string;
    page?: number;
    perPage?: number;
    filter: KanbanFilter;
  }): Promise<Pagination> {
    const url = `${environment.api.baseURL}/my-grants`;

    const params = new HttpParams()
      .set('order_by', orderBy)
      .set('order_direction', orderDirection)
      .set('page', page)
      .set('per_page', perPage);

    const body = {
      text: filter.searchText ?? '',
      base_budget_from: filter.baseBudgetFrom,
      base_budget_to: filter.baseBudgetTo,
      cpvs: (filter.cpvs || []).map(cpv => cpv.id),
      regions: (filter.regions || []).map(region => region.id),
      deadline_max_days: filter.deadlineMaxDays ?? 'null',
      with_analizing: filter.withAnalizing,
      with_loss: filter.withLoss,
      with_preparing: filter.withPreparing,
      with_resignation: filter.withResignation,
      with_won: filter.withWon,
      without_tag: filter.withoutTag,
    };

    const response = await firstValueFrom(
      this.http
        .post<PaginationResponse>(url, body, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(grant =>
              Grant.fromResponse(grant)
            );
            return response;
          })
        )
    );

    return response;
  }

  async getMyGrantById(grantId: number): Promise<Grant> {
    const url = `${environment.api.baseURL}/my-grants/${grantId}`;

    const response = await firstValueFrom(
      this.http
        .get<GrantResponse>(url)
        .pipe(map(response => Grant.fromResponse(response)))
    );

    return response;
  }

  async updateProposalStatus(
    grantId: number,
    proposal: Proposal | null
  ): Promise<any> {
    const event =
      proposal?.status === ProposalStatus.SAVED ||
      proposal?.status === ProposalStatus.DISCARD
        ? ProposalStatusEvent[proposal!.status] + ' licitación'
        : 'Mover licitación al estado ' + ProposalStatusEvent[proposal!.status];
    this.trackerService.trackEvent(event, { grantId });

    const url = `${environment.api.baseURL}/my-grants/${grantId}/status`;
    const body: UpdateProposalStatusRequest =
      UpdateProposalStatusRequest.fromProposal(proposal);
    return await firstValueFrom(this.http.post(url, body));
  }

  async updateProposalTag(
    grantId: number,
    proposalTag: ProposalTag | null
  ): Promise<any> {
    const url = `${environment.api.baseURL}/my-grants/${grantId}/tag`;
    const body: UpdateProposalTagRequest =
      UpdateProposalTagRequest.fromProposalTag(proposalTag);
    return await firstValueFrom(this.http.post(url, body));
  }

  async share(grantId: number, share: GrantShare): Promise<any> {
    const url = `${environment.api.baseURL}/my-grants/${grantId}/share`;
    const body: GrantShareRequest = GrantShareRequest.fromGrantShare(share);
    this.trackerService.trackEvent(Measurement.SHARE_GRANT);
    return await firstValueFrom(this.http.post(url, body));
  }

  async shareAsAnonymous(grantId: number, share: GrantShare): Promise<any> {
    const url = `${environment.api.baseURL}/grants/${grantId}/share`;
    const body: GrantShareRequest = GrantShareRequest.fromGrantShare(share);
    this.trackerService.trackEvent(Measurement.SHARE_GRANT);
    return await firstValueFrom(this.http.post(url, body));
  }

  async addNote(grantId: number, note: GrantNote): Promise<any> {
    const url = `${environment.api.baseURL}/my-grants/${grantId}/notes`;
    const body: GrantNoteRequest = GrantNoteRequest.fromGrantNote(note);
    this.trackerService.trackEvent(Measurement.WRITE_NOTE, { value: note });
    return await firstValueFrom(this.http.post(url, body));
  }

  async updateNote(grantId: number, note: GrantNote): Promise<any> {
    const url = `${environment.api.baseURL}/my-grants/${grantId}/notes/${note.id}`;
    const body: GrantNoteRequest = GrantNoteRequest.fromGrantNote(note);
    return await firstValueFrom(this.http.put(url, body));
  }

  async getLastSync(): Promise<any> {
    const url = `${environment.api.baseURL}/grant-syncs/latest`;
    return await firstValueFrom(this.http.get(url));
  }
}
