import { firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Company, CompanyFinantial, User, UserSettings } from '@app/models';
import {
  CompanyFinantialResponse,
  CompanyResponse,
  UserResponse,
  UserSettingsResponse,
} from '@app/models/dto/response';
import {
  CompanyFinantialRequest,
  CompanyRequest,
  UserRequest,
  UserSettingsRequest,
} from '@app/models/dto/request';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileRepository {
  constructor(private http: HttpClient) {}

  async getMe(): Promise<User> {
    const url = `${environment.api.baseURL}/me`;
    const response = await firstValueFrom(
      this.http
        .get<UserResponse>(url)
        .pipe(map(response => User.fromResponse(response)))
    );

    return response;
  }

  async updateMe(user: User): Promise<any> {
    const url = `${environment.api.baseURL}/me`;
    const body = UserRequest.fromUser(user);
    return await firstValueFrom(this.http.put(url, body));
  }

  async updatePassword(
    user: User,
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    const url = `${environment.api.baseURL}/me`;
    const body = UserRequest.fromUser(user);
    body.password = newPassword;
    body.old_password = oldPassword;
    return await firstValueFrom(this.http.put(url, body));
  }

  async getMyCompany(): Promise<Company> {
    const url = `${environment.api.baseURL}/me/company`;
    const response = await firstValueFrom(
      this.http
        .get<CompanyResponse>(url)
        .pipe(map(response => Company.fromResponse(response)))
    );
    return response;
  }

  async createMyCompany(company: Company): Promise<any> {
    const url = `${environment.api.baseURL}/me/company`;
    const body = CompanyRequest.fromCompany(company);
    return await firstValueFrom(this.http.post(url, body));
  }

  async updateMyCompany(
    company: Company,
    notifyByEmail: boolean = false
  ): Promise<any> {
    const url = `${environment.api.baseURL}/me/company`;
    const body = CompanyRequest.fromCompany(company, notifyByEmail);
    return await firstValueFrom(this.http.put(url, body));
  }

  async updateMyCompanyBillingData(company: Company): Promise<any> {
    const url = `${environment.api.baseURL}/me/company/billing`;
    const body = CompanyRequest.fromCompany(company);
    return await firstValueFrom(this.http.put(url, body));
  }

  async getMyCompanyFinantials(): Promise<CompanyFinantial[]> {
    const url = `${environment.api.baseURL}/me/company/finantials`;
    const response = await firstValueFrom(
      this.http
        .get<CompanyFinantialResponse[]>(url)
        .pipe(
          map(response =>
            response.map((item: CompanyFinantialResponse) =>
              CompanyFinantial.fromResponse(item)
            )
          )
        )
    );
    return response;
  }

  async createMyCompanyFinantial(
    companyFinantial: CompanyFinantial
  ): Promise<any> {
    const url = `${environment.api.baseURL}/me/company/finantials`;
    const body = CompanyFinantialRequest.fromCompanyFinantial(companyFinantial);
    return await firstValueFrom(this.http.post(url, body));
  }

  async updateMyCompanyFinantial(
    companyFinantial: CompanyFinantial
  ): Promise<any> {
    const url = `${environment.api.baseURL}/me/company/finantials/${companyFinantial.id}`;
    const body = CompanyFinantialRequest.fromCompanyFinantial(companyFinantial);
    return await firstValueFrom(this.http.put(url, body));
  }

  async deleteMyCompanyFinantial(
    companyFinantial: CompanyFinantial
  ): Promise<any> {
    const url = `${environment.api.baseURL}/me/company/finantials/${companyFinantial.id}`;
    return await firstValueFrom(this.http.delete(url));
  }

  async getMySettings(): Promise<UserSettings> {
    const url = `${environment.api.baseURL}/me/settings`;
    const response = await firstValueFrom(
      this.http
        .get<UserSettingsResponse>(url)
        .pipe(map(response => UserSettings.fromResponse(response)))
    );
    return response;
  }

  async updateMySettings(settings: UserSettings): Promise<any> {
    const url = `${environment.api.baseURL}/me/settings`;
    const body = UserSettingsRequest.fromUserSettings(settings);
    return await firstValueFrom(this.http.put(url, body));
  }

  async refreshToken(): Promise<string> {
    const url = `${environment.api.baseURL}/auth/refresh`;
    const response: any = await firstValueFrom(this.http.get(url));

    return response.token;
  }

  async deleteAccount(): Promise<any> {
    const url = `${environment.api.baseURL}/me`;
    return await firstValueFrom(this.http.delete(url));
  }
}
